import ajax from './niu_http';

// 基因查询
export function listDetail(params) {
    return ajax.post('/gene/listDetail', params)
}

// 查询页面查询条件
export function fetchFieldValues(){
    return ajax.get('/sample/fieldValues ')
}

// 获取样本列表
export function fetchSampleList(params){
    return ajax.post('/sample/list',params)
}

// 统计main_tissue和detail_tissue的样本数量
export function fetchStatTissue(){
    return ajax.get('/sample/statTissue')
}

// 统计breed的样本数量
export function fetchStatBreed(){
    return ajax.get('/sample/statBreed')
}

// 查看可搜索样本字段
export function fetchSampleFields(){
    return ajax.get('/sample/fields')
}

// -------------------  micRNA  -------------------------
// 查看表达量
export function fetchRcMicRNAList() {
    return ajax.get('/rc/list_miRNA')
}

// micRNA基因列表
export function fetchMicRNAList() {
    return ajax.get('/gene/list_miRNA')
}

// 查看样本
export function fetchMicRNASampleList(params){
    return ajax.post('/sample/list_miRNA',params)
}

// 查询页面查询条件
export function fetchMicRNAFieldValues(){
    return ajax.get('/sample/fieldValues_miRNA')
}

// 查看可搜索样本字段
export function fetchMicRNASampleFields(){
    return ajax.get('/sample/fields_miRNA')
}

// 组织统计信息
export function fetchMicRNAStatTissue(){
    return ajax.get('/sample/statTissue_miRNA')
}

// 品系统计信息
export function fetchMicRNAStatBreed(){
    return ajax.get('/sample/statBreed_miRNA')
}

// miRNA_target_search:获取所有有target的miRNA
export function fetchMiRNATargetList(){
    return ajax.get('/gene/list_miRNAHasTarget')
}

// miRNA_target_search:查询某些miRNA的target
export function fetchMiRNATarget(data){
    return ajax.post('/gene/getMirnaTarget',data)
}

// miRNA_list_info:查询miRNA信息
export function fetchMiRNAInfo(data){
    return ajax.post('/gene/list_miRNA_info',data)
}

// miRNA project list:查询项目列表
export function fetchMiRNAProjectList(data){
    return ajax.post('/project/list_miRNA',data)
}

// RNA-Array project list:查询项目列表
export function fetchRNAArrayProjectList(data){
    return ajax.post('/project/list_RNA_array',data)
}