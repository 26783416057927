export default {
    color:["#F9F8F4","#EEE8D8","#F6DED4","#F4D7B7","#F8DCAD","#EEC784","#E6B094",'#D8CAAF',"#DADAD8","#F0C4C5","#EFD6CD","#E5DBDC","#BCB3B4","#C1ADAF","#A27E7E"],
    tooltip: {
        trigger: 'item',
        formatter:'{b}     {c}'
    },
    title:{
        text:'Main_tissue',
        left:'center',
        top:'48%',
        textStyle:{
            fontSize: 20,
            color:'#454c5c',
            align:'center'
        },
    },
    series: [
        {
            name: 'Main_tissue',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
                // show: true,
                show: false,
                position: 'center',
                textBorderColor:"transparent",
                // formatter:'{a|{a}} \n {b|135393 of 699585} \n {c|samples}',
                // rich:{
                //     a:{
                //         fontWeight:600,
                //         fontSize:21,
                //         color:"#000000"
                //     },
                //     b:{
                //         fontSize: 19,
                //         fontWeight:500,
                //         padding: [10, 0, 10, 0]
                //     },
                //     c:{
                //         fontSize: 19,
                //         fontWeight:500,
                //     }
                // }
            },
            labelLine: {
                show: false
            },
            data: []
        }
    ]
}