export default {
    tooltip: {
        trigger: 'item'
    },
    series: [{
        type: 'wordCloud',
        shape: 'pentagon',
        keepAspect: true,
        //此处添加图片的base64格式
        maskImage: null,
        // left: 'center',
        // top: 'center',
        width: '100%',
        height: '100%',
        right: null,
        bottom: null,
        // size:['100%','100%'],
        sizeRange: [12, 40], //字体大小区间
        rotationRange: [-90, 90], // 文本旋转范围
        rotationStep: 45, //文本旋转步进度
        gridSize: 8, //越大单词间距越大
        drawOutOfBound: false, //设置为true，允许文字部分在画布外绘制。
        layoutAnimation: true,
        shrinkToFit: true,//如果字体太大而无法显示文本，是否收缩文本。如果将其设置为false，则文本将不渲染。如果设置为true，则文本将被缩小。
        textStyle: {
            fontFamily: 'sans-serif',
            fontWeight: 'bold',
            color: function () {
                return 'rgb(' + [
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160),
                    Math.round(Math.random() * 160)
                ].join(',') + ')';
            }
        },
        // emphasis: {
        //     // focus: 'self',
        //     textStyle: {
        //         textShadowBlur: 3,
        //         textShadowColor: '#333'
        //     }
        // },
        //data属性中的value值越大，权重就越大，展示字体就越大
        data:[]
    }]
}